@tailwind base;
@tailwind components;
@tailwind utilities;

input::-webkit-calendar-picker-indicator {
  display: none !important;
}

datalist {
  visibility: hidden;
}

datalist.active {
  visibility: visible;
}
